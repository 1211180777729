<!-- +++++++++++==Thẻ và import==++++++++++++page-container-table
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
 <button-all-header
        :contentBtnAdd="'Thêm'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteMore"
        @clickDowloadSample="dowloadFile"
        @clickExportExcel="downloadFileAll"
        @importFile="fileChange($event)"
        @clickAdd="$router.push('position-job/add')"
        @search="search($event)"
      />
Hướng dẫn truyền:
    :contentBtnAdd=Nội dung nút thêm mới
     :arrayExecl : Truyền mảng các trường execel
     :showBtnMultiDelete : để show hay ẩn nút delete
     @clickDelete : kích chuột xảy ra delete
     @clickDowloadSample: Tải tập tin mẫu
     @clickExportExcel: Xuất file excel cho người dùng
     @importFile: Dự liệu nhận về là dữ liệu đã chuyển từ excel thành mảng JS
     @search : Nhận lại giá trị search
     @clickAdd : Xảy ra sự kiện click khi nhấn nút tạo
Thuộc tính truyền ẩn đi : truyền false là nó ẩn trường đó đi
      hideDelete : nút xóa
      hideImportFile : nút import file xử lý
      hideExportFile : nút tải file về
      hideDowload : nút dowload file mẫu
      hideSearch : ô tìm kiếm
      hideAdd : button thêm

-->
<template>
  <div class="d-flex justify-content-between">
    <div
      id="btn-all-header"
      class="d-flex"
    >
      <!-- -------------Delete------------- -->
      <button-component
        v-if="hideDelete"
        v-b-tooltip.hover.top="'Xóa nhiều'"
        class="ml-2 button-icon"
        content-btn=""
        variant="danger"
        icon-btn="Trash2Icon"
        :disabled="!showBtnMultiDelete"
        @click="$emit('clickDelete')"
      />
      <!--Import file excel để xử lý-->
      <div
        v-if="hideImportFile"
        class="button-import ml-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.top="'Thêm tập tin mẫu'"
          for="file"
          type="file"
          variant="primary"
          class="btn-icon-file"
        >
          <input
            id="file"
            ref="file"
            type="file"
            class="input-file"
            @change="importFileExcel"
          />
          <label
            for="file"
          ><feather-icon
            icon="Link2Icon"
            for="file"
            class="input_label text-white"
          /></label>
        </b-button>
      </div>
      <!-- ---Tải tập tin mẫu ------------ -->
      <button-component
        v-if="hideDowload"
        v-b-tooltip.hover.top="'Tải tập tin mẫu'"
        class="ml-1 button-icon"
        content-btn=""
        variant="outline-primary"
        icon-btn="DownloadIcon"
        @click="$emit('clickDowloadSample')"
      />
      <!-- ------Xuất file excel cho người dùng------- -->
      <b-button
        v-if="hideExportFile"
        v-b-tooltip.hover.top="'Xuất Excel'"
        variant="outline-primary"
        class="ml-1 button-icon-custom"
        @click="$emit('clickExportExcel')"
      >
        <span class="icon-excel pr-25">
        </span>
      </b-button>
    </div>
    <div class="header-action">
      <search
        v-if="hideSearch"
        class="search-input"
        @change="search"
      />
      <button-component
        v-if="hideAdd"
        class="ml-1"
        :content-btn="contentBtnAdd"
        variant="primary"
        icon-btn="PlusIcon"
        @click="$emit('clickAdd')"
      />
    </div>
  </div>

</template>

<script >
import {
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import readXlsxFile from 'read-excel-file'
import Ripple from 'vue-ripple-directive'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'

export default {
  components: {

    FeatherIcon,
    ButtonComponent,
    BButton,
    VBTooltip,
    Search,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    showBtnMultiDelete: {
      type: Boolean,
      default: false,
    },
    arrayExcel: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    contentBtnAdd: {
      type: String,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: 'Thêm',
    },
    hideDelete: {
      type: Boolean,
      default: true,
    },
    hideImportFile: {
      type: Boolean,
      default: true,
    },
    hideExportFile: {
      type: Boolean,
      default: true,
    },
    hideDowload: {
      type: Boolean,
      default: true,
    },
    hideSearch: {
      type: Boolean,
      default: true,
    },
    hideAdd: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    // import file exel
    importFileExcel() {
      const input = this.$refs.file.files[0]
      const dataInput = []
      // eslint-disable-next-line no-undef
      readXlsxFile(input).then(rows => {
        if (rows.length > 0) {
          /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
          for (let i = 2; i < rows.length; i++) {
            const c = this.arrayExcel.reduce((previousValue, currentValue, currentIndex) => {
              previousValue[currentValue] = this.checkRowExcel(rows[i][currentIndex])
              return previousValue
            }, {})
            dataInput.push(c)
          }
          this.$emit('importFile', dataInput)
        }
      })
    },
    checkRowExcel(a) {
      if (!a) {
        return a
      }
      return a.toString()
    },
    // Tìm kiếm
    search(val) {
      this.$emit('search', val)
    },
  },
}
</script>

<style lang="scss">
#btn-all-header{
    .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }
   .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
.search-input {
    width: 400px;
  }

</style>
